<template>
    <div>
        <button @click.prevent="initializeScan">Scan Barcode</button>
        <div class="barcode-scan-overlay" v-if="overlayVisible">
            <video ref="video" class="barcode-scan-video" width="300" height="200"></video>
            <div :class="{'barcode-scan-loading-indicator': true, 'barcode-scan-loading-indicator--visible': loadingIndicatorVisible}"></div>
            <button class="barcode-scan-close" @click="abort">Schließen</button>
        </div>
    </div>
</template>

<script>
import { BrowserBarcodeReader, DecodeHintType, BarcodeFormat } from '@zxing/library';

export default {
    name: 'ScanButton',
    props: {},
    data() {
        return {
            overlayVisible: false,
            codeReader: null,
            lastResult: null,
            lastResultConfirmations: 0,
            lastResultConfirmationsNeeded: 3,
            lastResultTimeout: 2000,
            lastResultTimeoutHandle: null,
        }
    },
    mounted() {

    },
    computed: {
        loadingIndicatorVisible() {
            return this.lastResult && this.lastResultConfirmations > 0;
        }
    },
    methods: {
        initializeScan() {
            // try to manually get permission from user
            navigator.mediaDevices.getUserMedia({
                video: {facingMode: 'environment'}
            }).then((/*stream*/) => {
                this.initializeXzing();
            }).catch(function(err) {
                console.error(err);
            });
        },
        initializeXzing() {
            this.lastResult = null;
            this.lastResultConfirmations = 0;
            this.lastResultTimeoutHandle = null;

            this.codeReader = new BrowserBarcodeReader(100, new Map(Object.entries({
                [DecodeHintType.POSSIBLE_FORMATS]: [BarcodeFormat.CODE_128],
            })));

            /*codeReader.listVideoInputDevices()
                .then(videoInputDevices => {
                    console.log(videoInputDevices);
                    videoInputDevices.forEach(device =>
                        console.log(`${device.label}, ${device.deviceId}`)
                    );
                }).catch(err => console.error(err));*/

            this.overlayVisible = true;

            // video element needs to be present in DOM, so wait for next tick
            this.$nextTick(() => {
                this.codeReader
                    .decodeFromVideoDevice(undefined, this.$refs.video, this.handleDecoded)
                    .then(this.handleDecoded)
                    .catch(err => console.error(err));
            });
        },
        handleDecoded(result, error) {
            if( error ) {
                //console.error(error);
                //console.log(++this.tmpCounter);
                return;
            }

            const code = result.text;

            //console.log(code);

            if( this.lastResult === code ) {
                ++this.lastResultConfirmations;
            }
            else {
                this.lastResult = code;
                this.lastResultConfirmations = 0;
            }

            if( this.lastResultTimeoutHandle ) {
                clearTimeout(this.lastResultTimeoutHandle);
                this.lastResultTimeoutHandle = null;
            }

            if( this.lastResultConfirmations >= this.lastResultConfirmationsNeeded ) {
                //console.warn('CONFIRMED! emitting now');
                this.$emit('scanned', code);

                this.closeScanner();

                return;
            }

            this.lastResultTimeoutHandle = setTimeout(() => {
                this.lastResult = null;
                this.lastResultConfirmations = 0;
            }, this.lastResultTimeout);
        },

        abort() {
            this.closeScanner();
        },

        closeScanner() {
            this.codeReader.reset();
            this.codeReader = null;
            this.overlayVisible = false;
        },
    },
    components: {}
}
</script>

<style scoped lang="css">
.barcode-scan-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background: #cccccc;
}

.barcode-scan-video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.barcode-scan-close {
    position: absolute;
    right: 20px;
    top: 20px;
}

.barcode-scan-loading-indicator {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -40px;
    margin-left: -40px;

    display: inline-block;
    width: 80px;
    height: 80px;

    pointer-events: none;
    transition: opacity 0.2s ease;
    opacity: 0.0;
}
.barcode-scan-loading-indicator.barcode-scan-loading-indicator--visible {
    opacity: 0.9;
}
.barcode-scan-loading-indicator:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
