<template>
    <div id="app">
        <div style="margin-bottom:100px;"><img alt="Vue logo" src="./assets/logo.png"></div>
        <!--HelloWorld msg="Welcome to Your Vue.js App"/-->
        <!--ScanButtonQuagga /-->
        <ScanButton @scanned="(value) => this.result = value"/>
        <br>
        <div v-if="result">
            Scan result: {{result}}
        </div>
        <br>
        <!--
        Force numpad-keyboard on mobile:
        <input type="text" inputmode="numeric" pattern="\d*">
        -->
    </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue';
//import ScanButtonQuagga from './components/ScanButtonQuagga.vue';
import ScanButton from './components/ScanButton.vue';

export default {
    name: 'App',
    data() {
        return {
            result: null,
        }
    },
    components: {
        //HelloWorld,
        //ScanButtonQuagga,
        ScanButton,
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
</style>
